"use client";

import { MoonIcon, SunIcon } from "@radix-ui/react-icons";
import { useTheme } from "next-themes";
import { Button } from "./ui/button";

const ThemeToggler = () => {
    const { theme, setTheme } = useTheme();

    const handleThemeToggle = () => {
        setTheme(theme === "dark" ? "light" : "dark");
    };

    return (
        <div>
            <Button
                variant="outline"
                size="icon"
                onClick={handleThemeToggle}
                aria-label={
                    theme === "dark"
                        ? "Activer le mode clair"
                        : "Activer le mode sombre"
                } 
            >
                <SunIcon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                <MoonIcon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            </Button>
        </div>
    );
};

export default ThemeToggler;
