import React from 'react';
import Link from 'next/link';

const Logo = () => {
  return (
    <Link href='/' className='text-primary font-bold'>
      Jérémie.Deldem
    </Link>
  );
};

export default Logo;
