"use client";

import Footer from "@/components/Footer";
import Header from "@/components/Header";
import ThemeProvider from "@/components/ThemeProvider";
import { Inter, Outfit, Space_Grotesk } from "next/font/google";
import "./globals.css";
import { Toaster } from "sonner";
import Head from "next/head";
import { usePathname } from "next/navigation";

const inter = Inter({ subsets: ["latin"] });
const outfit = Outfit({ subsets: ["latin"] });
const grotesk = Space_Grotesk({ subsets: ["latin"] });

export default function RootLayout({ children }) {
    const pathname = usePathname();

    const isHomepage = pathname === "/";

    return (
        <html lang="fr" suppressHydrationWarning className="mobile-scrollbar">
            <Head>
                {/* Meta tags pour l'indexation */}
                <title>
                    Portfolio de Développeur Web Fullstack | Jérémie Deldem
                </title>
                <meta
                    name="description"
                    content="Développeur Web Fullstack spécialisé dans la création de sites intuitifs, modernes et professionnels. Avec Next.js, Tailwind, et Framer Motion, je m'engage à fournir des solutions innovantes pour réussir votre projet."
                />
                <meta name="robots" content="index, follow" />

                <meta
                    name="keywords"
                    content="site internet, vente en ligne, marketplace, site vitrine, site internet pas cher, développeur web, fullstack, création site web, portfolio, Jérémie Deldem"
                />
                <meta name="author" content="Jérémie Deldem" />
                <meta name="language" content="fr" />
                <meta name="robots" content="index, follow" />
                <meta name="geo.region" content="FR-OCC" />
                <meta
                    name="geo.placename"
                    content="Lodève, Montpellier, Hérault, Occitanie, France"
                />

                {/* Open Graph pour les réseaux sociaux */}
                <meta
                    property="og:title"
                    content="Portfolio de Développeur Web Fullstack | Jérémie Deldem"
                />
                <meta
                    property="og:description"
                    content="Développeur Web fullstack spécialisé dans la création de sites intuitifs, modernes et professionnels. Avec Next.js, Tailwind, et Framer Motion, je m'engage à fournir des solutions innovantes pour réussir votre projet."
                />
                <meta property="og:url" content="https://jdeldem.pro" />
                <meta property="og:type" content="website" />

                {/* Twitter Cards */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="Portfolio de Développeur Web Fullstack | Jérémie Deldem"
                />
                <meta
                    name="twitter:description"
                    content="Développeur web fullstack spécialisé dans la création de sites intuitifs, modernes et professionnels. Avec Next.js, Tailwind, et Framer Motion, je m'engage à fournir des solutions innovantes pour réussir votre projet."
                />
            </Head>
            <body className={outfit.className}>
                <ThemeProvider attribute="class" defaultTheme="light">
                    <Toaster position="bottom-center" richColors />
                    {!pathname.startsWith("/projets/") ? (
                        <>
                            <Header />
                            {children}
                            <Footer />
                        </>
                    ) : (
                        <>{children}</>
                    )}
                </ThemeProvider>
            </body>
        </html>
    );
}
