import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { AlignJustify } from "lucide-react";
import Nav from "./Nav";
import Logo from "./Logo";
import Socials from "./Socials";
import { useState, useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";

const MobileNav = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    // const pathname = usePathname();
    // const searchParams = useSearchParams();

    // useEffect(() => {
    //   setMenuOpen(false);
    // }, [pathname, searchParams]);

    return (
        <Sheet open={menuOpen} onOpenChange={setMenuOpen}>
            <SheetTrigger asChild>
                <AlignJustify
                    className="cursor-pointer "
                    aria-label="Ouvrir le menu"
                    role="button"
                    tabIndex={0}
                />
            </SheetTrigger>
            <SheetContent>
                <div className="flex flex-col items-center justify-between h-full py-8">
                    <div className="flex flex-col items-center gap-y-32 text-2xl mt-12">
                        <Logo />
                        <Nav
                            containerStyles="flex flex-col items-center gap-y-6"
                            setOpen={setMenuOpen}
                        />
                    </div>
                    <Socials
                        containerStyles="flex gap-x-4 text-3xl"
                        iconStyles="text-4xl"
                    />
                </div>
            </SheetContent>
        </Sheet>
    );
};

export default MobileNav;
